.button {
  @apply relative font-normal text-15 leading-[21px] tracking-base inline-flex items-center justify-center
    whitespace-pre transition border-2 border-transparent rounded-full focus:outline-none;
}

.link .button {
  @apply w-full;
}

.button.primary {
  @apply text-black bg-green-500
      hover:bg-grey-700 hover:text-green-500
      focus:bg-grey-700 focus:text-green-500 focus:border-green-500
      disabled:opacity-20 disabled:bg-green-500 disabled:text-black disabled:cursor-not-allowed;

  padding: 13px 21px 13px 19px;
}

.button.secondary {
  @apply text-white bg-black
      hover:bg-grey-300 hover:text-black
      focus:bg-grey-300 focus:text-black focus:border-black
      disabled:cursor-not-allowed disabled:opacity-20 disabled:bg-black disabled:text-white;

  padding: 13px 18px;
}

.button.counter {
  @apply text-white bg-black
    hover:bg-grey-300 hover:text-black
    focus:bg-grey-300 focus:text-black focus:border-black
    disabled:cursor-not-allowed disabled:opacity-20 disabled:bg-black disabled:text-white;

  padding: 14px 100px 14px 18px;
}

.button.share {
  @apply text-black bg-grey-200
    hover:bg-grey-300 hover:text-black
    focus:bg-grey-300 focus:text-black focus:border-black
    disabled:cursor-not-allowed disabled:opacity-20 disabled:bg-black disabled:text-white;

  padding: 13px 18px;
}

.button.sharePost {
  @apply bg-black text-white;
}

@screen md {
  .button.counter {
    justify-content: flex-start;
  }
}

.iconWrapper {
  width: 17px;
  min-width: 17px;
  margin-right: 10px;
}

/* Per-icon type tweaks */
.iconWrapper-apple {
  /* The Apple logo visually belongs a bit higher than the center */
  position: relative;
  top: -2px;
}

:global(.dark) .button.secondary,
:global(.dark) .button.counter,
.forceDarkMode.button.secondary,
.forceDarkMode.button.counter {
  @apply text-black bg-white
      hover:bg-grey-700 hover:text-white
      focus:bg-grey-700 focus:text-white focus:border-white
      disabled:bg-white disabled:text-black;
}

:global(.dark) .button.share,
.forceDarkMode.button.share {
  @apply text-white bg-grey-800
      hover:bg-grey-500 hover:text-white
      focus:bg-grey-500 focus:text-white focus:border-white
      disabled:cursor-not-allowed disabled:opacity-20 disabled:bg-black disabled:text-white;
}

.button svg {
  @apply transition text-white;
}

.button.share svg {
  @apply transition text-black;
}
.button.sharePost svg {
  @apply text-white;
}

:global(.dark) .button.share svg,
.forceDarkMode.button.share svg {
  @apply transition text-white;
}

:global(.dark) .button svg,
.forceDarkMode.button svg {
  @apply text-black;
}

.button:hover svg,
.button:focus svg {
  @apply text-black;
}

:global(.dark) .button:hover svg,
:global(.dark) .button:focus svg,
.forceDarkMode.button:hover svg,
.forceDarkMode.button:focus svg {
  @apply text-white;
}

.button:hover:disabled svg,
.button:focus:disabled svg,
.button:disabled svg {
  @apply text-white;
}

:global(.dark) .button:hover:disabled svg,
:global(.dark) .button:focus:disabled svg,
:global(.dark) .button:disabled svg,
.forceDarkMode.button:hover:disabled svg,
.forceDarkMode.button:focus:disabled svg,
.forceDarkMode.button:disabled svg {
  @apply text-black;
}

.counterElement {
  @apply bg-white;

  right: 0;
  position: absolute;
  height: 100%;
  border-radius: 100px;
  display: inline-block;
  padding: 0 15px;
}

:global(.dark) .counterElement,
.forceDarkMode .counterElement {
  @apply bg-black;
}

.counterElementText {
  @apply text-black font-neutral;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

:global(.dark) .counterElementText,
.forceDarkMode .counterElementText {
  @apply text-white transition;
}

:global(.dark) .button:hover .counterElementText,
:global(.dark) .button:focus .counterElementText,
.forceDarkMode.button:hover .counterElementText,
.forceDarkMode.button:focus .counterElementText {
  @apply text-black;
}

.button:hover .counterElement,
.button:focus .counterElement {
  @apply bg-black transition;
}

:global(.dark) .button:hover .counterElement,
:global(.dark) .button:focus .counterElement,
.forceDarkMode.button:hover .counterElement,
.forceDarkMode.button:focus .counterElement {
  @apply bg-white;
}

.button:hover:disabled .counterElementText,
.button:focus:disabled .counterElementText,
.button:disabled .counterElementText {
  @apply text-black;
}

:global(.dark) .button:hover:disabled .counterElementText,
:global(.dark) .button:focus:disabled .counterElementText,
:global(.dark) .button:disabled .counterElementText,
.forceDarkMode.button:hover:disabled .counterElementText,
.forceDarkMode.button:focus:disabled .counterElementText,
.forceDarkMode.button:disabled .counterElementText {
  @apply text-white;
}

.button:hover:disabled .counterElement,
.button:focus:disabled .counterElement,
.button:disabled .counterElement {
  @apply bg-white;
}

:global(.dark) .button:hover:disabled .counterElement,
:global(.dark) .button:focus:disabled .counterElement,
:global(.dark) .button:disabled .counterElement,
.forceDarkMode.button:hover:disabled .counterElement,
.forceDarkMode.button:focus:disabled .counterElement,
.forceDarkMode.button:disabled .counterElement {
  @apply bg-black;
}
